import renderComponents from './components';

const NAME = 'sw-book-trip';

class BookTripModule {
  constructor(name, context, opts) {
    this.name = name;
    this.box = opts.box;
    this.context = context;

    const companiesService = context.getService('Companies');
    const companyService = context.getService('Company');
    const filterService = context.getService('Filter');
    const tripService = context.getService('Trip');

    filterService.searchOrdersFilter();

    this.page = renderComponents(this.box, {
      toTrip: this.moveToTrip,
      companiesService,
      filterService,
      tripService,
      companyService,
      moveToCompany: this.moveToCompany,
    });
  }

  moveToTrip = (trip, e) => {
    const path = `/account/${trip.AccountId}/company/${trip.Companies[0]}/trip/${trip.Id}`;
    const tripService = this.context.getService('Trip');

    tripService.set(trip);

    if (e.button === 1) {
      window.open(path);
      return;
    }

    if (e.button === 2) {
      return;
    }

    if (e.target.tagName.toLowerCase() === 'a') {
      return;
    }

    this.context.navigate(path);
  };

  moveToCompany = (trip) => {
    const url = `/account/${trip.AccountId}/company/${trip.Companies[0]}/account/profile`;
    window.open(url, '_blank');
  };

  destroy() {
    this.page.unmount();
  }
}

export default function (core) {
  core.addModule(NAME, BookTripModule);
}
