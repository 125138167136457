import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dialog } from 'sw-ui';

import COMPONENTS from '../../../bi/constants/components';
import { FIELDS } from '../../../bi/constants/approveUsers';
import DecisionButtons from '../../../components/DecisionButtons';

import Input from '../../../components/input';

import styles from '../styles/addSAUserForm.module.css';

const {
  INPUT: { TYPE: { TEXT } },
} = COMPONENTS;

const LABELS = {
  TITLE: 'Добавление нового Smartagent',
  NAME: 'Имя',
  EMAIL: 'Email',
  PHONE: 'Phone',
  CLOSE: 'Отменить',
  SAVE: 'Добавить',
};

class AddSAUserForm extends Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    userService: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    onRegister: PropTypes.func.isRequired,
  };

  state = {
    isValid: false,
    user: {
      Name: '',
      Email: '',
      Phone: '',
    },
    validation: {
      Name: '',
      Email: '',
      Phone: '',
    },
  };

  checkValidation = () => {
    const { user, validation } = this.state;

    const isNoError = !Object.keys(validation).some(field => !!validation[field]);
    const isValue = !Object.keys(user).some(field => !user[field]);
    const isValid = isNoError && isValue;

    this.setState({
      isValid,
    });
  };

  handleChangeInput = (e, field, value) => {
    const { user, validation } = this.state;

    this.setState({
      user: {
        ...user,
        [field]: value,
      },
      validation: {
        ...validation,
        [field]: value ? '' : validation[field],
      },
    }, this.checkValidation);
  };

  handleValidationInput = (e, field) => {
    const result = this.props.userService.userFieldValidation(field, this.state.user[field]);

    this.setState({
      validation: {
        ...this.state.validation,
        [field]: result,
      },
    }, this.checkValidation);
  };

  render() {
    const { onRegister, onClose, label } = this.props;
    const {
      isValid,
      user,
      user: { Name, Email, Phone },
      validation: { Name: validName, Email: validEmail, Phone: validPhone },
    } = this.state;

    return (
      <Dialog onClick={ onClose }>
        <div className={ styles.wrapped }>
          <div className={ styles.title }>
            { label }
          </div>
          <div>
            <Input
              field={ FIELDS.NAME }
              type={ TEXT }
              value={ Name }
              label={ LABELS.NAME }
              valid={ validName }
              onChange={ this.handleChangeInput }
              onBlur={ this.handleValidationInput }
            />
          </div>
          <div>
            <Input
              field={ FIELDS.EMAIL }
              type={ TEXT }
              value={ Email }
              label={ LABELS.EMAIL }
              valid={ validEmail }
              onChange={ this.handleChangeInput }
              onBlur={ this.handleValidationInput }
            />
          </div>
          <div>
            <Input
              field={ FIELDS.PHONE }
              type={ TEXT }
              value={ Phone }
              label={ LABELS.PHONE }
              valid={ validPhone }
              onChange={ this.handleChangeInput }
              onBlur={ this.handleValidationInput }
            />
          </div>
          <div className={ styles.actions }>
            <DecisionButtons
              loadingIncluded
              disabled={ !isValid }
              labelSave={ LABELS.SAVE }
              labelCancel={ LABELS.CLOSE }
              onSave={ () => onRegister(user) }
              onCancel={ onClose }
            />
          </div>
        </div>
      </Dialog>
    );
  }
}

export { AddSAUserForm };
