import renderComponents from './components';

const NAME = 'sw-amo-crm';

class AmoCrmModule {
  constructor(name, context, opts) {
    this.name = name;
    this.box = opts.box;
    this.context = context;

    const amoCRMService = context.getService('AmoCRM');
    const appService = context.getService('App');
    const { params } = appService.get();

    this.page = renderComponents(this.box, {
      amoCRMService,
      params,
    });
  }

  destroy() {
    this.page.unmount();
  }
}

export default function (core) {
  core.addModule(NAME, AmoCrmModule);
}
