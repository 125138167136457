import React from 'react';
import { createRoot } from 'react-dom/client';

import AutoSendingEmails from '../../components/AutoSendingEmails';

const renderComponents = (box, opts) => {
  const { businessService } = opts;

  const page = (
    <AutoSendingEmails businessService={ businessService } />
  );

  const root = createRoot(box);

  root.render(page);

  return root;
};

export default renderComponents;
